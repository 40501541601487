import React from "react"

export default class MailchimpSubscribe extends React.Component {

  render() {
      return (
        <form 
          action="https://getform.io/f/e9f34acb-3002-473d-bbb9-cd46931cb4ad" 
          method="POST"
          className="w-full rounded-xl border border-gray-200 text-sm p-2 mt-3"
        >

          <input
            type="text"
            name="name"
            placeholder="Name" 
            required
            className="w-full border border-gray-200 rounded-lg px-2 py-1 mr-2"
          /> 
          
          <input
            type="email"
            name="email"
            placeholder="Email" 
            required
            className="w-full border border-gray-200 rounded-lg px-2 py-1 mr-2 mt-2"
          />
          
          <button
            type="submit"
            className="bg-orange-500 rounded-lg text-white px-2 py-1 mt-2"
          >
            Subscribe
          </button>
        </form>
      )
    }
  }







