import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/footer"

import flow from "../images/flow.jpg"
import wave from "../images/wave.svg"


const CaseStudy = () => {
  return (
    <Layout>
      <SEO title="Case Study" description="Case" image={flow} />

      <section className="w-full relative bg-gray-800 bg-cover bg-center" style={{backgroundImage: `url(https://lookaside.fbsbx.com/elementpath/media/?media_id=684822609047887)`}}>
        <header className="w-full text-center px-5 py-20 md:py-24 bg-gradient-to-t from-black">
          <div className="inline-block w-auto mx-auto h-20 p-2 bg-white rounded-lg">
            <img src="https://flow-living-development.s3.eu-west-1.amazonaws.com/public/logo_85954137b7.png" alt="" 
            className="block h-16 mx-auto" />
          </div>
          <h1 className="text-3xl md:text-5xl text-white shadow-sm font-bold leading-none tracking-tight md:mt-12">
            Tamatem
          </h1>
          <p className="text-md md:text-xl font-light text-white shadow-sm mt-3">
            Winning back lapsed gamers with Facebook video ads
          </p>
        </header>
      </section>

      <section className="max-w-3xl mx-auto text-center mt-24">
        <h1 className="text-2xl md:text-4xl text-center font-bold text-gray-700 leading-none mt-4 md:mt-3">
          Objective
        </h1>
        <p className="text-md md:text-xl font-light text-gray-400 mt-3">
          The Jordanian gaming company brought back lapsed players using
          concurrent Facebook video ad campaigns to reach audiences and boost
          app installs that, together, achieved a 10X higher return on ad spend
          than previous campaigns.
        </p>
      </section>

      <section className="w-full md:px-10 relative bg-gray-100 md:py-24 mt-24">
        <div class="max-w-3xl mx-auto flex flex-col md:flex-row justify-between">
          
          <div class="w-64 mx-auto text-center mt-5">
              <h3 class="text-5xl font-black text-orange-500 ">3x</h3>
              <p class="font-light text-sm text-gray-400 mr-4">
                increase in click-through rate
              </p>
          </div>

          <div class="w-64 mx-auto text-center mt-5">
            <h3 class="text-5xl font-black text-orange-500 ">200%</h3>
            <p class="font-light text-sm text-gray-400 mr-4">
              more returning players than before the campaign
            </p>
          </div>

          <div class="w-64 mx-auto text-center mt-5">
            <h3 class="text-5xl font-black text-orange-500 ">70%</h3>
            <p class="font-light text-sm text-gray-400 mr-4">
              higher return on ad spend, compared to previous app install
              campaigns
            </p>
          </div>

        </div>
      </section>

      <section
        id="solution"
        className="mt-12 md:mt-0 py-10 md:py-32 px-5 mx-5 md:mx-10 rounded-xl"
      >
        <div className="max-w-4xl mx-auto flex flex-col-reverse md:flex-row">
          <div className="w-full md:w-1/2 text-center md:text-left">
            <h2 className="text-2xl md:text-4xl text-center md:text-left font-bold text-gray-700 leading-none mt-4 md:mt-3">
              Solution
            </h2>
            <p className="text-md md:text-xl text-center md:text-left font-light text-gray-400 max-w-md mt-4 mb-10">
            Tamatem is the leading mobile games publisher in the Arabic-speaking market, offering numerous chart-topping free games with optional in-app purchases. The Tamatem team works directly with international games developers, applying its local expertise and technical know-how in player acquisition, distribution and monetisation to make games culturally relevant and popular with gamers in Arabic-speaking markets.
            </p>

          </div>
          <div className="flex w-full md:w-1/2">
            <img
              className="bg-gray-100 self-center mx-auto w-full md:w-2/3 mb-10 md:mb-0 rounded-xl"
              src="#"
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="w-full px-5 md:mb-24">

        <h2 className="text-xl md:text-2xl text-center font-bold text-gray-700 leading-none mt-4 md:mt-3">
          Products used
        </h2>

        <div class="max-w-3xl mx-auto flex flex-col md:flex-row justify-between mt-10">
          
          <div class="w-full md:w-64 text-center mt-5">
            <div class="mx-auto flex w-12 h-12 border-4 border-orange-200 rounded-full bg-orange-400">
            </div>
              <h3 class="text-lg font-bold text-gray-700 mt-4">Video ads</h3>
              <p class="font-light text-sm text-gray-400 mr-4">
                Capture attention with engaging video ads.
              </p>
          </div>

          <div class="w-full md:w-64 text-center mt-5">
            <div class="mx-auto flex w-12 h-12 border-4 border-orange-200 rounded-full bg-orange-400">
            </div>
            <h3 class="text-lg font-bold text-gray-700 mt-4">Automatic placements</h3>
            <p class="font-light text-sm text-gray-400 mr-4">
              Optimise your ads to find the most efficient placement.
            </p>
          </div>

          <div class="w-full md:w-64 text-center mt-5">
            <div class="mx-auto flex w-12 h-12 border-4 border-orange-200 rounded-full bg-orange-400">
            </div>
            <h3 class="text-lg font-bold text-gray-700 mt-4">Custom audiences</h3>
            <p class="font-light text-sm text-gray-400 mr-4">
              Reach your customers and contacts on Facebook.
            </p>
          </div>

        </div>
      </section>

      <section className="container mx-auto px-5 md:px-0">
        <div className="bg-gray-100 w-full rounded-xl p-8 md:p-24">
          <p className="font-medium tracking-tight text-gray-700 text-xl md:text-3xl">
            “These days, all of our testing and soft launches are done on Facebook. Thanks to all the creative testing we’ve done to date, we saw a huge increase in click-through rate that we never experienced before with a card game. In this re-engagement campaign, the results turned out to be amazing!”
          </p>
          <div className="inline-flex mt-4 leading-none">
            <img className="w-10 h-10 mr-2" src="https://flow-living-production.s3.eu-west-1.amazonaws.com/public/kevin_e417b4c380_2509a60562.png" alt="" />
            <div className="mt-1 text-left">
              <p className="">Lamia Shreim</p>
              <p className="text-sm text-gray-400 mt-1">Marketing Manager, Tamatem</p>
            </div>
          </div>
        </div>
      </section>

      <img className="w-full mt-10" src={wave} alt="" />

      <Footer />
    </Layout>
  )
}

export default CaseStudy
