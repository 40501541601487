import React from "react"
import { Link } from "gatsby"
import logo from "../images/logo.svg"
import MailchimpSubscribe from "../components/mailchimp_subscribe"

const Footer = () => {

  return (
    <footer className="container mx-auto">
      <div className="flex flex-wrap mt-12 md:mt-24 px-6 md:px-0">
        <div className="w-full md:w-2/5 mt-10 md:pr-10">
          <h4 className="font-medium text-gray-800">Subscribe to Flow News</h4>
          <p className="font-light text-sm text-gray-400 mt-4">Stay ahead with news, trends and tips to help build your business through the proptech revolution</p>
          <MailchimpSubscribe />
        </div>

        <div className="w-1/2 md:w-1/5 mt-10">
          <h4 className="font-medium text-gray-800">Flow</h4>
          <ul className="mt-4">
            <li>
              <Link
                to="/agencies"
                className="font-light text-sm text-gray-400"
              >
                For Agencies
              </Link>
            </li>
            <li>
              <Link
                to="/developers"
                className="font-light text-sm text-gray-400"
              >
                For Developers
              </Link>
            </li>
            <li>
              <a href="/partners" target="_blank" className="font-light text-sm text-gray-400">
                Partner Portal
              </a>
            </li>
            <li>
              <Link
                to="/partner-portal"
                className="font-light text-sm text-gray-400"
              >
                Become a Partner
              </Link>
            </li>
          </ul>
        </div>

        <div className="w-1/2 md:w-1/5 mt-10">
          <h4 className="font-medium text-gray-800">Resources</h4>
          <ul className="mt-4">
            <li>
              <Link
                to="/blog"
                className="font-light text-sm text-gray-400"
              >
                Blog
              </Link>
            </li>
            <li>
              <Link
                to="/case-studies"
                className="font-light text-sm text-gray-400"
              >
                Case Studies
              </Link>
            </li>
            <li>
              <Link
                to="/flowfindings"
                className="font-light text-sm text-gray-400"
              >
                FlowFindings
              </Link>
            </li>
          </ul>
        </div>

        <div className="w-full md:w-1/5 mt-10">
          <h4 className="font-medium text-gray-800">Follow Flow</h4>
          <div className="w-full text-orange-500 mt-4">
            <a href="https://twitter.com/flowlivingcom" target="_blank">
              <i className="border rounded-full border-orange-500 hover:bg-orange-500 hover:text-white cursor-pointer p-2 mr-1 md:mx-1 fab fa-twitter"></i>
            </a>
            <a href="https://www.facebook.com/flowlivingcom" target="_blank">
              <i className="border rounded-full border-orange-500 hover:bg-orange-500 hover:text-white cursor-pointer p-2 mr-1 md:mx-1 fab fa-facebook"></i>
            </a>
            <a href="https://www.instagram.com/flowlivingcom" target="_blank">
              <i className="border rounded-full border-orange-500 hover:bg-orange-500 hover:text-white cursor-pointer p-2 mr-1 md:mx-1 fab fa-instagram"></i>
            </a>
            <a
              href="https://www.linkedin.com/company/flowliving"
              target="_blank"
            >
              <i className="border rounded-full border-orange-500 hover:bg-orange-500 hover:text-white cursor-pointer p-2 mr-1 md:mx-1 fab fa-linkedin-in"></i>
            </a>
          </div>
        </div>
      </div>
      <div className="px-4 md:px-10 py-24 text-center">
        <img className="h-4 mx-auto" src={logo} alt="" />
        <p className="w-full text-xs text-gray-400 mt-4">
          Copyright © 2021 Flow Living (PTY) Ltd. All rights reserved. <br/>
          <a 
            href="/termsofuse" 
            target="_blank"
            className="underline mx-1"
          >
            Terms of use
          </a>
          <a 
            href="/privacypolicy" 
            target="_blank"
            className="underline mx-1"
          >
            Privacy Policy
          </a>
        </p>
      </div>
    </footer>
  )
}

export default Footer
